<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- <img class="banner_box" :src="this.url.imgUrl + bannerImg" alt="立优教育" /> -->
    <!-- 导航 -->
    <div id="nav_bar_box">
      <ul>
        <li>
          <div class="red" :class="[isNav == item.id ? 'nav_name_active' : '']" v-for="item in newsTag" :key="item.id"
            @click="getNews(item.id)">
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <!-- <router-link :to="{name:'About'}">跳转</router-link> -->
    <!-- 版心 -->
    <div id="content">
      <!-- 标题 -->
      <!--资讯中心内容 -->
      <ul class="information_box">
        <li class="information_item" v-for="item in information" :key="item.id" @click="goNewsInfo(item)">
          <div class="information">
            <img :src="item.image" alt="立优教育" />
            <div class="right">
              <div id="right_title">{{ item.title }}</div>
              <div class="right_desc">{{ item.brief_desc }}</div>
              <ul>
                <li>{{ item.createtime }}</li>
              </ul>
            </div>
          </div>
          <div id="line"></div>
        </li>
      </ul>
      <!-- 版心结尾 -->
    </div>
    <div class="pagesize">
      <el-pagination @current-change="pageclick" background :current-page="datas.page" layout="prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      total: 0,
      bannerImg: "/assets/img/infomation.jpg", //banner
      isNav: 1135,
      newsTag: [
        { id: 1135, name: "一级建造师" },
        { id: 1145, name: "二级建造师" },
        { id: 1165, name: "执业药师" },
        { id: 1154, name: "中级注册安全工程师" },
        // { id: 1138, name: "健康管理师" },
      ],
      information: [],
      datas: {
        page: 1, //页码，默认1
        page_size: 10, //每页显示多少条，默认10
        type: 2, //资讯类型，默认1 1教育资讯 0平台资讯
        course_category_id: "", //资讯所属科目类别
      },
    };
  },

  methods: {
    // 咨询标签点击事件
    getNews(id) {
      console.log(id);
      this.isNav = id;
      this.datas.course_category_id = id;
      this.datas.page = 1;
      this.getlist();
    },
    pageclick(e) {
      console.log(e);
      this.datas.page = e;
      this.getlist();
    },
    getlist() {
      api.article(this.datas).then((res) => {
        this.information = res.data.data.list;
        this.total = res.data.data.total_item_count;
      });
    },
    // 去资讯详情页
    goNewsInfo(e) {
      // console.log(e);
      if (e.metatype == 2) {
        window.open(e.content_url);
      } else {
        this.$router.push({
          name: "Info",
          params: { is_pageid: 5, info_id: e.id },
        });
      }
    },
  },
  created() {
    api.category_list().then((res) => {
      let a = [];
      res.data.data.list.forEach((e) => (a = [...a, ...e.children]));
      if (this.$route.query.type) {
        switch (Number(this.$route.query.type)) {
          case 1:
            this.isNav = 1135
            this.datas.course_category_id = 1135 //一建
            break;
          case 2:
            this.isNav = 1145
            this.datas.course_category_id = 1145 //二建
            break;
          case 3:
            this.isNav = 1154
            this.datas.course_category_id = 1154 //安全
            break;
          case 4:
            this.isNav = 1165
            this.datas.course_category_id = 1165 //监理
            break;
          case 5:
            this.isNav = 1291
            this.datas.course_category_id = 1291 //药师
            break;
        }
      } else {
        this.datas.course_category_id = 1135
      }
      this.newsTag = a;
      this.getlist();
    });
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination.is-background .el-pager li {
  background: #fff;
}

.pagesize {
  width: 1200px;
  margin: 0 auto 30px;
  display: flex;
  justify-content: flex-end;
}

#app {
  background: #f5f5f5;
}

.banner_box {
  width: 100%;
  /* height: 360px; */
  height: 400px;
  background: #eee;
}

/* 导航 */

#nav_bar_box {
  width: 1200px;
  background: #fff;
  height: 70px;
  margin: 20px auto 0;
  border-radius: 6px;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}

#nav_bar_box ul {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#nav_bar_box ul li {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#nav_bar_box ul li div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  /* color: #202B3A; */
  // margin-right: 65px;
  text-align: center;
  padding: 7px 20px;
  cursor: pointer;
}

.nav_name_active {
  color: #fff;
  padding: 7px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}

/* 版心 */

#content {
  background: #fff;
  padding: 30px 50px;
  width: 1100px;
  /* height: 1000px; */
  margin: 20px auto 60px;
  border-radius: 6px;
  /* overflow: hidden; */
}

/* 资讯中心内容 */

.information_box {
  /* width: 100%; */
  width: 1100px;
  margin: 0px 0 30px;
  /* overflow: hidden; */
}

.information_box .information_item {
  height: 209px;
  position: relative;
  cursor: pointer;
}

.information_box .information_item .information {
  height: 209px;
  display: flex;
  align-items: center;
}

.information_item .information img {
  width: 224px;
  height: 149px;
}

.information_item .information .right {
  height: 149px;
  display: flex;
  flex-flow: column;
  /* justify-content: space-between; */
  margin-left: 31px;
}

.information_item .information .right #right_title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #363636;
}

.information_item .information .right .right_desc {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 19px;
  /* margin: 19px 0 58px; */
}

.right ul li {
  position: absolute;
  bottom: 29px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #989898;
}

.information_box .information_item #line {
  width: 1100px;
  height: 1px;
  background: #000000;
  opacity: 0.1;
}

.information_box .information_item:last-child #line {
  display: none;
}
</style>
